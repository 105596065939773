// base on https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-create-rule-schedule.html#eb-cron-expressions
// and
// https://gist.github.com/ultrasonex/e1fdb8354408a56df91aa4902d17aa6a

const minutesRegex =
  /^([*]|([0-5]?\d)|((([0-5]?\d)|(\*))\/([0-5]?\d))|(([0-5]?\d)-([0-5]?\d))|((([0-5]?\d)|(\*))(,(([0-5]?\d)|(\*)))*))$/;

const hoursRegex =
  /^([*]|[01]?\d|2[0-3]|((([01]?\d|2[0-3]?)|(\*))\/([01]?\d|2[0-3]?))|(([01]?\d|2[0-3]?)-([01]?\d|2[0-3]?))|((([01]?\d|2[0-3]?)|(\*))((,)(([01]?\d|2[0-3]?)|(\*))){0,23}))$/;

const monthRegex =
  /^([*]|([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|((([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|(\*))\/(([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))|((([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))-(([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))|((([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|(\*))((,)(([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|(\*)))*))$/;

const dayOfWeekRegex =
  /^([*]|[?]|([1-7]L?)|(SUN|MON|TUE|WED|THU|FRI|SAT)|((([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT))(-|,|#)(([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)))|((([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)|(\*))\/(([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)))|((([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)|(\*))((,)(([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)|(\*)))*))$/;

const yearRegex =
  /^([*]|([1-2]\d{3})|(((([1-2]\d{3})|(\*)))\/((\d{0,4})))|(([1-2]\d{3})-([1-2]\d{0,3}))|((([1-2]\d{3})|(\*))((,)(([1-2]\d{3})|(\*)))*))$/;

const createValidate = (regex: RegExp) => (value: string) => regex.test(value);

export function validateDayOfMonth(input: string): boolean {
  function valid(part: string): boolean {
    if (part === '*' || part === '?' || part === 'L' || part === 'W') return true;

    if (part.endsWith('W')) {
      const num = parseInt(part.slice(0, -1), 10);
      return num >= 1 && num <= 31;
    }

    if (part.startsWith('L-')) {
      const num = parseInt(part.slice(2), 10);
      return num >= 1 && num <= 30;
    }

    if (part.includes('-')) {
      const [start, end] = part.split('-').map(Number);
      return start! >= 1 && end! >= 1 && start! <= 31 && end! <= 31 && start! < end!;
    }

    if (part.includes('/')) {
      const [base, step] = part.split('/').map(Number);
      return base! > 0 && base! <= 31 && step! > 0 && step! <= 31;
    }

    if (part.includes(',')) {
      return part.split(',').every((p) => valid(p.trim()));
    }

    const num = parseInt(part, 10);
    return num >= 1 && num <= 31;
  }

  return input.split(',').every((part) => valid(part.trim()));
}

export const validateMinutes = createValidate(minutesRegex);
export const validateHours = createValidate(hoursRegex);
export const validateMonth = createValidate(monthRegex);
export const validateDayOfWeek = createValidate(dayOfWeekRegex);
export const validateYear = (value: string) => {
  if (!yearRegex.test(value)) return false;
  const year = Number.parseInt(value);

  // will be NaN for , - * / and we already check it with yearRegex
  return Number.isNaN(year) || (year >= 1970 && +year <= 2199);
};
